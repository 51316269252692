import React, { Component } from "react";
import styled from "styled-components";
import { component } from "../component";

@component
export class Field extends Component<{
  label;
  postfix?;
  id?;
  children?;
  labelFor?;
  error?;
  errorMessage?;
  hintId?;
}> {
  static styles = styled.div`
    .label {
      font-family: Nunito;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      display: block;
      margin-left: 8px;
    }

    .postfix {
      color: var(--empty-state-secondary);
    }

    .error-message {
      color: var(--input-error-color);
      font-family: Nunito;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 8px;
    }

    input[type="text"],
    input[type="number"],
    input[type="email"],
    textarea {
      background: var(--input-default-bg);
      border: var(--input-default-border);
      box-shadow: var(--input-shadow);
      color: var(--input-text-color);
      border-radius: 16px;
      height: 48px;
      padding: 0 16px;
      width: 100%;
      box-sizing: border-box;
      font-family: Nunito;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;

      &::placeholder {
        color: var(--input-placeholder-text-color);
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
      }

      &:focus {
        border: var(--input-focus-border);
        background: var(--input-focus-bg);
        outline: var(--input-focus-border);
      }

      &.error {
        border: var(--input-error-border);
      }
    }

    textarea {
      height: 128px;
      padding: 16px;
    }
  `;
  render() {
    return (
      <>
        <label
          id={this.props.id}
          className="label"
          htmlFor={this.props.labelFor}
        >
          {this.props.label}
          {this.props.postfix ? (
            <>
              {" "}
              <span className="postfix">{this.props.postfix}</span>
            </>
          ) : null}
        </label>
        {this.props.children}
        {this.props.error ? (
          <p className="error-message" id={this.props.hintId}>{this.props.errorMessage}</p>
        ) : null}
      </>
    );
  }
}
