import React, { Component } from "react";
import styled from "styled-components";

import { component } from "../../component";
import { X } from "../../XObject";
import { cmsFields } from "../../cmsFields";
import {
  desktopBreakpoint,
  innerContCss,
  mobileBreakpoint,
} from "../../styleBits";
import { cms } from "../../cms";
import { state } from "../../state";
import { TestimonyCarousel } from "../TestimonyCarousel";
import { TestimonyFade } from "../TestimonalFade";

@component
export class TestimonialsSection extends Component {
  static styles = styled.div`
    position: relative;
    overflow: hidden;
    ${innerContCss}

    ${desktopBreakpoint} {
      padding: 64px 0;

      .desktop-testimonials {
        display: block;
      }

      .mobile-testimonials {
        display: none;
      }
    }

    ${mobileBreakpoint} {
      padding: 48px 0;

      .desktop-testimonials {
        display: none;
      }

      .mobile-testimonials {
        display: block;
      }

      .first-test-quote {
        display: none;
      }

      .first-test-author {
        display: none;
      }
    }

    .first-test-quote {
      position: relative;
      font-size: 36px;
      font-weight: 700;
      line-height: 48px;

      > span {
        display: inline;
      }

      &:before {
        content: "“";
        ${desktopBreakpoint} {
          position: absolute;
          left: -10px;
          top: -10px;
          font-weight: 700;
          font-size: 18px;
        }
      }
    }

    .first-test-author {
      display: block;
      text-align: right;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 48px;
    }
  `;

  state = X({
    page: 0,
  });

  render(Container?) {
    const testimonials = cms(cmsFields.Testimonials).map((entry) => {
      return {
        text: entry[cmsFields.Testimonials_Text],
        author: entry[cmsFields.Testimonials_Author],
      };
    });
    const [first, ...rest] = testimonials;

    return (
      <Container>
        <div className="innerCont">
          <div className="desktop-testimonials">
            <div className="first-test-quote">
              <span>{first.text}</span>
              <span>&rdquo;</span>
            </div>
            <span className="first-test-author">&mdash; {first.author}</span>
            <TestimonyFade testimonials={rest} theme={state.theme} />
          </div>
          <div className="mobile-testimonials">
            <TestimonyCarousel
              testimonials={testimonials}
              theme={state.theme}
            />
          </div>
        </div>
      </Container>
    );
  }
}
