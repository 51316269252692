import React, { Fragment, useContext, useMemo } from "react";
import styled, { ThemeContext } from "styled-components";
import { format } from "date-fns";
import { TimelineMonth } from "../components/TimelineMonth";
import { mobileBreakpoint } from "../styleBits";

const Container = styled.div`
  display: grid;
  grid-auto-flow: row dense;
  grid-auto-rows: auto;
  grid-template-columns: 20% 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
  max-width: 1080px;
  margin: 0 auto;
  margin-top: 128px;
  padding-bottom: 64px;

  ${mobileBreakpoint} {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "."
      ".";
    margin-top: 24px;
    padding-bottom: 32px;
    text-align: center;
  }

  h2 {
    font-family: BryantWebBold;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -2px;
    text-align: center;
    color: var(--h2);
    margin-bottom: 48px;

    ${mobileBreakpoint} {
      margin-top: 48px;
    }
  }
`;

const Sidebar = styled.div`
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mobileBreakpoint} {
    padding-left: 0;
  }

  .months {
    display: flex;
    flex-direction: column;
    gap: 8px;

    a {
      text-decoration: none;
      font-family: BryantWebBold;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      color: var(--timeline-anchor);

      &.currentMonth {
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: -1px;
        color: var(--timeline-anchor-active);
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .year {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1px;
    color: var(--timeline-anchor-active);
  }

  .currentYear {
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -2px;
    color: var(--timeline-anchor-active);
  }
`;

const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mobileBreakpoint} {
    padding: 0 8px;
  }
`;

const BadgeWrapper = styled.div`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: calc(50% - 5px);
    bottom: -4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px dashed var(--timeline-border);
  }
`;

const MonthBadge = styled.div`
  font-family: BryantWebBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  border-radius: 40px;
  padding: 8px 16px;
  background: var(--timeline-badge);
  color: var(--timeline-badge-text);
  width: fit-content;
  position: relative;
  z-index: 1;
`;

function Timeline() {
  const theme: any = useContext(ThemeContext);
  const { loaded, data } = theme;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const titlesByYearMonth = useMemo(() => {
    if (!data) return [];

    const shaped = data.map((item: any) => ({
      ...item,
      date: Object.keys(item._meta)
        .map((key) => item._meta[key]?.timestamp)
        .filter(Boolean)
        .sort()[0],
    }));

    // Group by year and month using date-fns
    const grouped = shaped.reduce((acc: any, item: any) => {
      try {
        const year = format(new Date(item?.date), "yyyy");
        const month = format(new Date(item?.date), "MM");

        if (!acc[year]) {
          acc[year] = {};
        }

        if (!acc[year][month]) {
          acc[year][month] = [];
        }

        acc[year][month].push(item);

        return acc;
      } catch (err) {
        return acc;
      }
    }, {});

    return grouped;
  }, [data]);

  if (!loaded) return;

  return (
    <Container>
      <Sidebar>
        {Object.keys(titlesByYearMonth)
          .reverse()
          .map((year) => (
            <div key={year} className="months">
              <div
                className={
                  year === currentYear.toString() ? "currentYear" : "year"
                }
              >
                {year}
              </div>
              {Object.keys(titlesByYearMonth[year])
                .sort()
                .reverse()
                .map((month) => {
                  const monthName = format(new Date(+year, +month - 1), "MMMM");
                  return (
                    <a
                      href={`#${year}-${month}`}
                      key={month}
                      className={
                        month === currentMonth.toString() ? "currentMonth" : ""
                      }
                    >
                      {monthName}
                    </a>
                  );
                })}
            </div>
          ))}
      </Sidebar>
      <TimelineContainer>
        <h2>SignUp's Title Timeline</h2>

        {Object.keys(titlesByYearMonth)
          .reverse()
          .map((year) => (
            <Fragment key={year}>
              {Object.keys(titlesByYearMonth[year])
                .sort()
                .reverse()
                .map((month) => {
                  const monthName = format(new Date(+year, +month - 1), "MMMM");
                  return (
                    <Fragment key={monthName}>
                      <BadgeWrapper>
                        <MonthBadge key={month} id={`${year}-${month}`}>
                          {monthName}
                        </MonthBadge>
                      </BadgeWrapper>
                      <TimelineMonth items={titlesByYearMonth[year][month]} />
                    </Fragment>
                  );
                })}
            </Fragment>
          ))}
      </TimelineContainer>
    </Container>
  );
}

export default Timeline;
