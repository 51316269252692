import React, { useContext } from "react";
import { ThemeContext } from "styled-components";

import { Section1 } from "../components/sections/Section1";
import { SupportedMoviesSection } from "../components/sections/SupportedMoviesSection";
import { FrequentlyAskedQuestionsSection } from "../components/sections/FrequentlyAskedQuestionsSection";
import { PressSection } from "../components/sections/PressSection";
import { WorkWithUsSection } from "../components/sections/WorkWithUsSection";
import { GetInTouchSection } from "../components/sections/GetInTouchSection";
import { NewsletterSection } from "../components/sections/Newsletter";
import { TestimonialsSection } from "../components/sections/TestimonialsSection";
import { CTA } from "../components/sections/CTA";

function Home() {
  const theme: any = useContext(ThemeContext);
  const { loaded, data } = theme;
  if (!loaded) return;
  return (
    <>
      <CTA />
      <Section1 />
      <TestimonialsSection />
      <SupportedMoviesSection data={data} />
      <FrequentlyAskedQuestionsSection />
      <PressSection />
      <WorkWithUsSection />
      <GetInTouchSection />
      <NewsletterSection />
    </>
  );
}

export default Home;
