import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./App.css";
import { XObject } from "./XObject";
import { cmsData } from "./cmsData";
import { state } from "./state";
import { Header } from "./components/sections/Header";
import Home from "./pages/Home";
import Donate from "./pages/Donate";
import Stories from "./pages/Stories";
import Story from "./pages/Story";
import Timeline from "./pages/Timeline";
import Completion from "./pages/Completion";
import { Footer } from "./components/sections/Footer";

XObject.observe(state, "showMenu", () => {
  document.body.classList.toggle("showMenu", state.showMenu);
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/donate",
    element: <Donate />,
  },
  {
    path: "/completion",
    element: <Completion />,
  },
  {
    path: "/stories",
    element: <Stories />,
  },
  {
    path: "/story/:id",
    element: <Story />,
  },
  {
    path: "/timeline",
    element: <Timeline />,
  },
]);

function App() {
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    document.body.classList.add(localStorage.getItem("theme") || "light");
    (async () => {
      const [data, cms] = await Promise.all([
        (
          await fetch(
            "https://signup-extension-data.s3.amazonaws.com/data.json"
          )
        ).json(),
        (
          await fetch("https://signup-extension-data.s3.amazonaws.com/cms.json")
        ).json(),
      ]);
      setData(data);

      for (const key in cms) {
        cmsData[key] = cms[key];
      }
      setLoaded(true);

      // Adds signly after content has laoded
      const SIGNLY_SCRIPT =
        "https://cdn.signly.co/release/latest/signly-dynamic-american.umd.min.js";
      const script = document.createElement("script");
      script.src = SIGNLY_SCRIPT;
      document.body.append(script);
    })();
  }, []);

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        const hash = window.location.hash;
        if (hash) {
          // scroll to id element
          const id = hash.substr(1);
          const el = document.getElementById(id);
          if (el) {
            el.scrollIntoView();
          }
        }
      }, 500);
    }
  }, [loaded]);

  if (!loaded) return;
  return (
    <>
      <ThemeProvider
        theme={{
          cms: cmsData,
          data,
          loaded,
        }}
      >
        <Header />
        <main id="content">
          <RouterProvider router={router} />
        </main>
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
