import React, { Component } from "react";
import styled from "styled-components";
import { component } from "../../component";
import { XInit } from "../../XObject";
import {
  desktopBreakpoint,
  mobileBreakpoint,
  innerContCss,
  largeTitleCss_desktop,
  largeTitleCss_mobile,
} from "../../styleBits";
import { Button, ButtonTheme } from "../Button";
import { Field } from "../Field";
import classNames from "classnames";

@component
export class NewsletterSection extends Component {
  state = XInit(
    class {
      errors = {
        email: false,
      };
    }
  );
  static styles = styled.div`
    ${innerContCss}

    .innerCont {
      flex-direction: column;
      ${desktopBreakpoint} {
        display: flex;
      }
    }

    .title {
      display: block;
      text-align: center;
      color: var(--h2);

      ${desktopBreakpoint} {
        ${largeTitleCss_desktop}
      }

      ${mobileBreakpoint} {
        ${largeTitleCss_mobile}
      }
    }

    p {
      font-size: 18px;
      max-width: 840px;
      margin: 0 auto;
    }

    .newsletter-form {
      display: flex;
      align-items: flex-end;
      width: 100%;
      gap: 24px;
      max-width: 840px;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 128px;

      &.hasError {
        align-items: center;
      }

      ${Field} {
        flex: 1;
        width: 100%;
      }
    }
  `;

  render() {
    return (
      <>
        <div className="innerCont" id="newsletter">
          <h2 className="title">Our Newsletter</h2>
          <p>
            We're constantly adding new videos and we are working on supporting
            more streaming services and devices (e.g tablets!). Please add your
            email address to stay up to date with our launches!
          </p>

          <form
            aria-label="Newsletter signup"
            className={classNames("newsletter-form", {
              hasError: this.state.errors["email"],
            })}
            method="post"
            target="_self"
            noValidate
            id="mc-embedded-subscribe-form"
            action="https://signupcaptions.us10.list-manage.com/subscribe/post?u=1201aba6c7d10fe02cb0567cd&amp;id=9a06aef872&amp;f_id=00a7d6e5f0"
          >
            <Field
              label="Email address"
              postfix="(required)"
              labelFor="mce-EMAIL"
              error={this.state.errors["email"]}
              errorMessage="Please enter a valid email address."
              hintId={"newsletter-email-hint"}
            >
              <input
                type="text"
                id="mce-EMAIL"
                name="EMAIL"
                required
                autoComplete="email"
                className={this.state.errors["email"] && "error"}
                aria-invalid={this.state.errors["email"]}
                aria-describedby="newsletter-email-hint"
              />
            </Field>
            <div
              aria-hidden="true"
              style={{ position: "absolute", left: "-5000px" }}
            >
              <input
                type="text"
                name="b_1201aba6c7d10fe02cb0567cd_9a06aef872"
                tabIndex={-1}
                readOnly={true}
                value=""
              />
            </div>
            <Button
              button
              type="submit"
              name="subscribe"
              id="mc-embedded-subscribe"
              onClick={(e) => {
                const email = document.getElementById(
                  "mce-EMAIL"
                ) as HTMLInputElement;

                if (!email.value.includes("@")) {
                  this.setState({ errors: { email: true } });
                  e.preventDefault();
                  email.focus();
                }
              }}
              theme={ButtonTheme.light_outline}
              text="Sign up"
            />
          </form>
        </div>
      </>
    );
  }
}
