import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Masonry from "react-masonry-css";
import { useDrag } from "react-use-gesture";
import { useSpring, animated } from "@react-spring/web";

import { TestimonyCard } from "./TestimonyCard";
import { ImageEnum, cssImg } from "../cssImg";

const Container = styled.div`
  cursor: grab;
  .testimonials {
    display: flex;
    margin-left: 16px;
    width: auto;
  }

  .pagesCont {
    display: flex;
    align-items: center;
    justify-content: center;
    .prev,
    .next {
      cursor: pointer;
      width: 48px;
      height: 48px;
      position: relative;
      border-radius: 50%;
      border: 1px solid var(--outline-border);
      transform: rotate(0deg);
      transition: transform 0.3s ease;
      flex: 0 0 auto;

      .icon {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: ${cssImg(ImageEnum.arrowForward)};
        background-position: center;
        background-repeat: no-repeat;
        filter: invert(11%) sepia(33%) saturate(7357%) hue-rotate(250deg)
          brightness(68%) contrast(121%);

        ${({ theme }) =>
          theme === "dark" &&
          `
            filter: invert(1);
          `}

        &.next {
        }
      }

      &:hover {
        background-color: var(--outline-focused);
        outline: 1px solid var(--outline-border);
      }
    }

    .prev {
      transform: rotate(180deg);
      margin-right: 14px;
    }
  }

  .pages {
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const TestimonyCarousel = ({ testimonials, theme }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [pages, setPages] = useState(3);
  const [page, setPage] = useState(1);
  const [{ x }, api] = useSpring(() => ({ x: 0, y: 0 }));
  const bind = useDrag(
    ({ swipe: [swipeX] }) => {
      if (swipeX > 0) {
        if (page === 1) {
          setPage(pages);
        } else {
          setPage(page - 1);
        }
      } else if (swipeX < 0) {
        if (page === pages) {
          setPage(1);
        } else {
          setPage(page + 1);
        }
      }
    },
    {
      axis: "x",
      useTouch: true,
    }
  );

  useEffect(() => {
    setWidth(document.getElementById("testimonials").offsetWidth);
  }, [ref]);

  useEffect(() => {
    setPages(Math.ceil(width / 320));
  }, [width, setPages]);

  useEffect(() => {
    api.start({ x: -((page - 1) * 316) });
  }, [page, api]);

  return (
    <Container theme={theme}>
      <animated.div
        {...bind()}
        style={{
          x,
          width: (testimonials.length / 2) * 305,
        }}
      >
        <Masonry
          id="testimonials"
          breakpointCols={testimonials.length / 2}
          className="testimonials"
          ref={ref}
          columnClassName="testimonial-column"
        >
          {testimonials.map((testimonial) => (
            <TestimonyCard
              key={testimonial.author}
              testimonial={testimonial}
              width="240px"
            />
          ))}
        </Masonry>
      </animated.div>
      <div className="pagesCont">
        <span
          className="prev"
          onClick={() => {
            if (page === 1) {
              setPage(pages);
            } else {
              setPage(page - 1);
            }
          }}
        >
          <div className="icon"></div>
        </span>
        <div className="pages">
          {page}/{pages}
        </div>
        <span
          className="next"
          onClick={() => {
            if (page === pages) {
              setPage(1);
            } else {
              setPage(page + 1);
            }
          }}
        >
          <div className="icon"></div>
        </span>
      </div>
    </Container>
  );
};
