interface RequestTitle {
  title: string;
  language: string;
  email: string;
}

export const saveToRequestedTitles = async (data: RequestTitle) => {
  const url = "https://backend.aslcaptioning.com:3710";

  await fetch(`${url}/request-title`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
