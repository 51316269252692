import React, { Component } from "react";
import styled from "styled-components";
import { component } from "../../component";
import {
  desktopBreakpoint,
  mobileBreakpoint,
  innerWidth,
} from "../../styleBits";
import { Button, ButtonTheme } from "../Button";
import { ImageEnum, imgBlob } from "../../cssImg";
import { state } from "../../state";
import { DropdownButton } from "../DropdownButton";

const w1024 = `@media (max-width: 1024px)`;
@component
class NavBar extends Component {
  static styles = styled.header`
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-left: -24px;
    margin-right: -24px;

    ${w1024} {
      margin: 0 16px;
    }

    #logo {
      display: flex;
      border: 2px solid transparent;
      padding: 4px;

      &:hover,
      &:focus {
        outline: 2px solid var(--outline-border);
        background: var(--outline-focused);
        border-radius: 16px;
      }
    }

    .logo {
      filter: invert(11%) sepia(48%) saturate(7280%) hue-rotate(250deg)
        brightness(79%) contrast(91%);

      ${({ theme }) =>
        theme === "dark" &&
        `
          filter: invert(1);
        `}
    }

    .donate-and-socials {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .socials {
      display: flex;

      ${desktopBreakpoint} {
        margin-left: auto;
        margin-right: 20px;
      }

      ${mobileBreakpoint} {
        display: none;
      }

      @media (max-width: 1024px) {
        display: none;
      }

      > * {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        padding: 12px;

        &:hover,
        &:focus {
          outline: 2px solid var(--outline-border);
          background-color: var(--outline-focused);
          border-radius: 50%;
        }

        img {
          filter: invert(11%) sepia(48%) saturate(7280%) hue-rotate(250deg)
            brightness(79%) contrast(91%);

          ${({ theme }) =>
            theme === "dark" &&
            `
              filter: invert(1);
            `}
        }
      }
    }

    .cont {
      ${desktopBreakpoint} {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ${mobileBreakpoint} {
        position: fixed;
        left: 0;
        right: 0;
        top: 80px;
        height: calc(100vh - 80px);
        background: var(--mobile-menu-bg);
        z-index: 1;
        overflow: auto;
        display: flex;
        flex-direction: column;
        padding: 0 16px;

        > * {
          flex: 0 0 auto;
        }
      }
    }

    .donate,
    .theme-selector {
      ${desktopBreakpoint} {
        &.mobile {
          display: none;
        }
      }

      ${mobileBreakpoint} {
        &.desktop {
          display: none;
        }
        margin-bottom: 8px;
        margin-top: 8px;
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      ${desktopBreakpoint} {
        display: flex;
        align-items: center;
      }
      a {
        color: var(--header-links);
        padding: 12px 16px;
        font-weight: 800;
        font-size: 16px;
        line-height: 16px;

        &:hover,
        &:focus {
          outline: 2px solid var(--outline-border);
          background: var(--outline-focused);
          border-radius: 24px;
        }

        @media (max-width: 830px) {
          padding: 0px 8px;
        }
      }

      ${mobileBreakpoint} {
        a {
          display: block;
          height: 70px;
          line-height: 70px;
          text-align: center;
        }
      }
    }

    a {
      text-decoration: none;
    }

    &:not(.showMenu) {
      .cont {
        ${mobileBreakpoint} {
          display: none;
        }
      }
    }

    .menu {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 40px;
      height: 40px;
      border: none;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;

      &:focus {
        outline: 2px solid var(--outline-border);
        border-radius: 50%;
        background: var(--outline-focused);
      }

      img {
        filter: invert(11%) sepia(48%) saturate(7280%) hue-rotate(250deg)
          brightness(79%) contrast(91%);

        ${({ theme }) =>
          theme === "dark" &&
          `
            filter: invert(1);
          `}
      }
    }

    ${desktopBreakpoint} {
      .menu {
        display: none;
      }
    }
  `;

  onEscPress = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      if (state.showMenu) {
        const menu: HTMLElement = document.querySelector(".menu");
        menu?.focus();
      }
      state.showMenu = false;
    }
  };

  componentDidMount(): void {
    window.addEventListener("keydown", this.onEscPress);
  }

  componentWillUnmount(): void {
    window.removeEventListener("keydown", this.onEscPress);
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    const main = document.querySelector("main");
    const footer = document.querySelector("footer");
    if (state.showMenu) {
      main.setAttribute("inert", "");
      main.setAttribute("aria-hidden", "true");

      footer.setAttribute("inert", "");
      footer.setAttribute("aria-hidden", "true");
    } else {
      main.removeAttribute("inert");
      main.removeAttribute("aria-hidden");

      footer.removeAttribute("inert");
      footer.removeAttribute("aria-hidden");
    }
  }

  render(Container?) {
    return (
      <Container className={state.showMenu && "showMenu"} theme={state.theme}>
        <a href="/" id="logo">
          <img
            className="logo"
            width={114}
            height={40}
            src={imgBlob(ImageEnum.logo)}
            alt="SignUp logo, opens home page"
          />
        </a>
        <button
          aria-label="Open menu"
          className="menu"
          onClick={() => {
            state.showMenu = !state.showMenu;
            document.querySelector("html").scrollTo({
              behavior: "smooth",
              top: 0,
              left: 0,
            });
          }}
        >
          <img src={imgBlob(ImageEnum.menu)} alt="" role="presentation" />
        </button>
        <nav className="cont" aria-label="Primary links">
          <DropdownButton
            className="theme-selector mobile"
            options={[
              {
                label: "Light mode",
                value: "light",
              },
              {
                label: "Dark mode (High contrast)",
                value: "dark",
              },
              {
                label: "Light mode (High contrast)",
                value: "high-contrast",
              },
            ]}
            text="Theme"
            value={state.theme}
            onChange={(value) => {
              if (value) {
                document.querySelector("body").classList.remove("light");
                document.querySelector("body").classList.remove("dark");
                document
                  .querySelector("body")
                  .classList.remove("high-contrast");
                document.querySelector("body").classList.add(value);
                localStorage.setItem("theme", value);
                state.theme = value;
                gtag("event", `Theme changed to ${value}`);
              }
            }}
          />
          <Button
            text="Donate"
            type="a"
            theme={ButtonTheme.secondary1}
            className="donate mobile"
            url="https://www.gofundme.com/f/help-fund-signup-captions-for-disney-plus"
            newWindow
          />
          <ul>
            <li>
              <a href="/#movies" onClick={() => (state.showMenu = false)}>
                Movies
              </a>
            </li>
            <li>
              <a href="/#faqs" onClick={() => (state.showMenu = false)}>
                FAQs
              </a>
            </li>
            {/* <li><a href="#our-team" onClick={() => state.showMenu = false}>Our Team</a></li> */}
            <li>
              <a href="/#press" onClick={() => (state.showMenu = false)}>
                Press
              </a>
            </li>
            <li>
              <a href="/#work-with-us" onClick={() => (state.showMenu = false)}>
                Work With Us
              </a>
            </li>
            <li>
              <a href="/#contact-us" onClick={() => (state.showMenu = false)}>
                Contact Us
              </a>
            </li>
            <li>
              <a href="/stories" onClick={() => (state.showMenu = false)}>
                Stories
              </a>
            </li>
            {state.showMenu ? (
              <li className="sr-only">
                <button
                  onClick={() => {
                    state.showMenu = false;
                    const menu: HTMLElement = document.querySelector(".menu");
                    menu?.focus();
                  }}
                >
                  Close Menu
                </button>
              </li>
            ) : null}
          </ul>
        </nav>
        <div className="donate-and-socials">
          <div className="socials">
            <a
              aria-label="Follow us on Twitter; opens in a new tab"
              href="https://twitter.com/SignUpCaptions"
              target="_blank"
              rel="noopener noreferrer"
              className="twitter"
            >
              <img src={imgBlob(ImageEnum.socials_twitter)} alt="" />
              <span role="presentation" className="hidden">
                Twitter
              </span>
            </a>
            <a
              aria-label="Follow us on Instagram; opens in a new tab"
              href="https://www.instagram.com/signupcaptions/"
              target="_blank"
              rel="noopener noreferrer"
              className="instagram"
            >
              <img src={imgBlob(ImageEnum.socials_instagram)} alt="" />
              <span role="presentation" className="hidden">
                Instagram
              </span>
            </a>
            <a
              aria-label="Follow us on Facebook; opens in a new tab"
              href="https://www.facebook.com/signupcaptions"
              target="_blank"
              rel="noopener noreferrer"
              className="facebook"
            >
              <img src={imgBlob(ImageEnum.socials_facebook)} alt="" />
              <span role="presentation" className="hidden">
                Facebook
              </span>
            </a>
            <a
              aria-label="Follow us on Linkedin; opens in a new tab"
              href="https://www.linkedin.com/in/mariella-satow/"
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin"
            >
              <img src={imgBlob(ImageEnum.socials_linkedIn)} alt="" />
              <span role="presentation" className="hidden">
                Linkedin
              </span>
            </a>
          </div>
          <DropdownButton
            className="theme-selector desktop"
            options={[
              {
                label: "Light mode",
                value: "light",
              },
              {
                label: "Dark mode (High contrast)",
                value: "dark",
              },
              {
                label: "Light mode (High contrast)",
                value: "high-contrast",
              },
            ]}
            text="Theme"
            value={state.theme}
            onChange={(value) => {
              if (value) {
                document.querySelector("body").classList.remove("light");
                document.querySelector("body").classList.remove("dark");
                document
                  .querySelector("body")
                  .classList.remove("high-contrast");
                document.querySelector("body").classList.add(value);
                localStorage.setItem("theme", value);
                state.theme = value;
                gtag("event", `Theme changed to ${value}`);
              }
            }}
          />
          <Button
            text="Donate"
            theme={ButtonTheme.secondary1}
            className="donate desktop"
            onClick={() => {
              gtag("event", "Header Donate Click");
            }}
            url="https://www.gofundme.com/f/help-fund-signup-captions-for-disney-plus"
            newWindow
          />
        </div>
      </Container>
    );
  }
}

@component
export class Header extends Component {
  static styles = styled.div`
    background: var(--surface-000);
    color: white;
    .innerCont {
      margin: 0 auto;
      ${desktopBreakpoint} {
        max-width: ${innerWidth};
      }
    }
  `;

  render() {
    return (
      <div className="innerCont">
        <NavBar />
      </div>
    );
  }
}
