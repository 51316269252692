import React, { Component } from "react";
import styled from "styled-components";
import { component } from "../../component";
import { XInit } from "../../XObject";
import { cmsFields } from "../../cmsFields";
import {
  desktopBreakpoint,
  mobileBreakpoint,
  innerContCss,
  largeTitleCss_desktop,
  largeTitleCss_mobile,
  largeTitleClassName,
} from "../../styleBits";
import { Button, ButtonTheme } from "../Button";
import { pictureUrl } from "../../pictureUrl";
import { cms } from "../../cms";

const getDomain = (url) => {
  if (!url) return;

  const publicationMap = {
    "https://variety.com": "Variety",
    "https://www.bbc.com": "BBC",
    "https://www.thetimes.co.uk": "The Times",
    "https://finance.yahoo.com": "Yahoo Finance",
    "https://www.upworthy.com": "Upworthy",
    "https://www.optimistdaily.com": "The Optimist Daily",
    "https://www.34st.com": "34th Street",
    "https://limpingchicken.com": "Limping Chicken",
    "https://issuu.com": "Issuu",
    "https://www.closingthegap.com": "Closing the Gap",
    "https://mickeyblog.com": "Mickey Blog",
    "https://chipandco.com": "Chip and Co",
    "https://www.forbes.com": "Forbes",
  };

  const urlObject = new URL(url);
  return publicationMap[urlObject.origin] || urlObject.origin;
};

@component
export class PressSection extends Component {
  static styles = styled.div`
    ${desktopBreakpoint} {
      padding: 128px 0 0 0;
    }
    ${mobileBreakpoint} {
      padding: 48px 0;
    }

    ${innerContCss}
    background: var(--alternative-section-bg);

    .innerCont {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
    }

    .${largeTitleClassName} {
      ${desktopBreakpoint} {
        ${largeTitleCss_desktop}
        margin-bottom: 64px;
      }
      ${mobileBreakpoint} {
        ${largeTitleCss_mobile}
        margin-bottom: 40px;
      }
      display: block;
      text-align: center;
      color: var(--alternative-header-color);
    }

    .tiles {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      ${desktopBreakpoint} {
        margin: 0 -32px;
      }
      ${mobileBreakpoint} {
        justify-content: space-between;
      }
      .tile {
        ${desktopBreakpoint} {
          width: 222px;
          height: 208px;
          margin: 32px;
          padding: 16px;
        }
        ${mobileBreakpoint} {
          width: calc(50% - 8px);
          aspect-ratio: 156/208;
          margin-bottom: 16px;
        }
        @media (max-width: 765px) {
          aspect-ratio: auto;
          padding-bottom: 32px;
          padding-left: 16px;
          padding-right: 16px;
        }

        border-radius: 16px;
        box-sizing: border-box;
        background-color: white;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background: var(--tile-bg);

        .photo {
          display: block;
          width: 100%;
          height: 128px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
        text-decoration: none;
        color: inherit;

        .readArticle {
          font-weight: 800;
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  `;

  state = XInit(
    class {
      showAll = false;
    }
  );

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (this.state.showAll) {
      const added: HTMLElement = document.querySelector(".tile.added");
      added?.focus();
    }
  }

  render() {
    const entries =
      cms(cmsFields.Press)?.map?.((entry, i) => {
        return {
          photo: `${pictureUrl(entry[cmsFields.Press_Picture])}`,
          url: entry[cmsFields.Press_URL],
          domain: getDomain(entry[cmsFields.Press_URL]),
        };
      }) || [];

    let initialEntries = entries.slice(0, 8);
    let remainingEntries = entries.slice(8);

    return (
      <div className="innerCont" id="press">
        <h2 className={largeTitleClassName}>Press</h2>
        <div className="tiles">
          {initialEntries.map((entry, i) => (
            <a
              className="tile"
              target="_blank"
              rel="noopener noreferrer"
              href={entry.url}
              key={entry.url + i}
            >
              <img className="photo" src={entry.photo} alt="" />
              {entry.url ? (
                <span className="readArticle">
                  Read Article
                  <span className="sr-only">
                    from {entry.domain} (opens in a new tab)
                  </span>
                </span>
              ) : null}
            </a>
          ))}

          {this.state.showAll &&
            remainingEntries.map((entry, i) => (
              <a
                className="tile added"
                target="_blank"
                rel="noopener noreferrer"
                href={entry.url}
                key={entry.url + i}
              >
                <img className="photo" src={entry.photo} alt="" />
                {entry.url && (
                  <span className="readArticle">
                    Read Article
                    <span className="sr-only">
                      from {entry.domain} (opens in a new tab)
                    </span>
                  </span>
                )}
              </a>
            ))}
        </div>

        <Button
          theme={ButtonTheme.secondary2}
          text={this.state.showAll ? "Show less" : "View All Press"}
          onClick={() => {
            this.state.showAll = !this.state.showAll;
          }}
        />
      </div>
    );
  }
}
