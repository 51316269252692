import React, { Component } from "react";
import styled from "styled-components";
import { component } from "../../component";
import { cssImg, ImageEnum, imgBlob } from "../../cssImg";
import { cmsFields } from "../../cmsFields";
import {
  desktopBreakpoint,
  mobileBreakpoint,
  innerContCss,
  otherBreakpoint,
  largeTitleCss_desktop,
  largeTitleCss_mobile,
  smallTitleCss,
  largeTitleCss,
  smallTitleClassName,
  largeTitleClassName,
} from "../../styleBits";
import { pictureUrl } from "../../pictureUrl";
import { cms } from "../../cms";

const GOOGLE_FORMS = [
  {
    title: "ASL",
    link: "https://forms.gle/hMaePXWn2ZU1zzJj8",
  },
  {
    title: "Auslan",
    link: "https://forms.gle/3cHvB4md7PYUg5Mz5",
  },
  {
    title: "BSL",
    link: "https://forms.gle/699ULui3nZ3N3nxG6",
  },
  {
    title: "ISL",
    link: "https://forms.gle/4kL4UeKs7zy2j7En6",
  },
  {
    title: "Other",
    link: "https://forms.gle/LfmpbqTcdKARmwSb8",
  },
];

@component
export class WorkWithUsSection extends Component {
  static styles = styled.div`
    background: var(--alternative-section-bg);
    color: var(--alternative-text-color);

    ${desktopBreakpoint} {
      padding: 128px 0;
    }
    ${mobileBreakpoint} {
      padding: 48px 0;
    }

    ${innerContCss}

    ${otherBreakpoint} {
      .innerCont {
        overflow: hidden;
      }
    }

    p {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }

    .workWithUs {
      ${desktopBreakpoint} {
        max-width: 1046px;
        padding-right: 566px;
        height: 525px;
      }

      ${mobileBreakpoint} {
        margin-bottom: 40px;
        padding-bottom: 100%;
      }

      box-sizing: border-box;
      position: relative;
      padding-top: 8px;
      margin: 0 auto;

      .google-form-container {
        display: flex;
        flex-direction: column;

        a {
          padding: 12px 16px 12px 0px;
          align-items: center;
          border-bottom: 1px solid var(--alternative-text-color);
          color: var(--alternative-text-color);
          font-family: Nunito;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          text-decoration: none;
          position: relative;
          display: flex;
          justify-content: space-between;

          p {
            margin: 0;
            padding: 0;
          }

          img {
            filter: var(--apply-now-filter);
          }
        }
      }

      .${smallTitleClassName} {
        ${smallTitleCss}
      }

      .${largeTitleClassName} {
        color: var(--alternative-header-color);
        ${desktopBreakpoint} {
          ${largeTitleCss_desktop}
        }
        ${mobileBreakpoint} {
          ${largeTitleCss_mobile}
          line-height: 48px;
        }
      }

      .photo1,
      .photo2 {
        border-radius: 12px;
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      ${desktopBreakpoint} {
        .photo1 {
          width: 248px;
          height: 331px;
          right: 200px;
          top: 0;
        }

        .photo2 {
          width: 294px;
          height: 231px;
          right: 0;
          bottom: 0;
        }
      }

      ${mobileBreakpoint} {
        .photo1 {
          width: 50%;
          height: auto;
          aspect-ratio: 248/331;
          bottom: 10%;
          left: 0;
        }
        .photo2 {
          width: 60%;
          height: auto;
          aspect-ratio: 294/231;
          bottom: 0;
          right: 0;
        }
      }
    }

    .makeADonation {
      ${desktopBreakpoint} {
        height: 399px;
        max-width: 1140px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      ${mobileBreakpoint} {
        padding-bottom: 70%;
        display: flex;
        flex-direction: column;
      }
      position: relative;

      .donateNow {
        margin: auto;
      }

      .${largeTitleClassName} {
        ${largeTitleCss}
      }

      p {
        ${desktopBreakpoint} {
          width: 470px;
          text-align: center;
        }
      }

      .photo1,
      .photo2,
      .photo3,
      .photo4 {
        border-radius: 12px;
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .photo1 {
        width: 225px;
        height: 177px;
        background-image: ${cssImg(ImageEnum.donation_1)};
        left: 0;
        top: 0;
      }

      .photo2 {
        width: 225px;
        height: 177px;
        background-image: ${cssImg(ImageEnum.donation_2)};
        left: 50px;
        bottom: 0;
      }

      .photo3 {
        width: 227px;
        height: 177px;
        background-image: ${cssImg(ImageEnum.donation_3)};
        right: 0;
        top: 0;
      }

      .photo4 {
        width: 225px;
        height: 177px;
        background-image: ${cssImg(ImageEnum.donation_4)};
        right: 60px;
        bottom: 66px;
      }

      ${mobileBreakpoint} {
        .photo1,
        .photo2 {
          display: none;
        }

        .photo3 {
          width: 50%;
          height: auto;
          aspect-ratio: 227/177;
          top: auto;
          left: auto;
          bottom: 15%;
          right: 15%;
        }

        .photo4 {
          width: 50%;
          height: auto;
          aspect-ratio: 225/177;
          top: auto;
          right: auto;
          bottom: 0;
          left: 15%;
        }
      }
    }
  `;

  render() {
    return (
      <div className="innerCont">
        <div className="workWithUs" id="work-with-us">
          <span className={smallTitleClassName}>Work With Us</span>
          <h2 className={largeTitleClassName}>
            {cms(cmsFields.WorkWithUs_Title)}
          </h2>
          <p>
            With more research, I discovered that SignUp could benefit the Deaf
            community, especially children. The fact that 4 in 5 children who
            sign have reading difficulties shocked me, and I realized that most
            mainstream media would be inaccessible as it has written captions.{" "}
          </p>
          <div className="google-form-container">
            {GOOGLE_FORMS.map(({ title, link }) => (
              <a
                key={title}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`Apply for ${title}; opens in a new tab`}
                onClick={() => {
                  gtag("event", "Apply Now Click");
                }}
              >
                <p>{title}</p>
                <img src={imgBlob(ImageEnum.arrowForward)} alt="" />
              </a>
            ))}
          </div>
          <span
            className="photo1"
            style={{
              backgroundImage: `url(${pictureUrl(
                cms(cmsFields.WorkWithUs_Photo1)
              )})`,
            }}
          />
          <span
            className="photo2"
            style={{
              backgroundImage: `url(${pictureUrl(
                cms(cmsFields.WorkWithUs_Photo2)
              )})`,
            }}
          />
        </div>
      </div>
    );
  }
}
