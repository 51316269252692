import React, { Component } from "react";
import cx from "classnames";

import { XInit } from "../XObject";
import { component, styled } from "../component";
import { ImageEnum, cssImg } from "../cssImg";
import { state } from "../state";

@component
export class DropdownSelect extends Component<{
  above?;
  options: { label; value }[];
  id?;
  value;
  onChange?;
  required?;
  ariaDescribedby?;
  ariaInvalid?;
}> {
  static styles = styled.div<{ theme }>`
    position: relative;

    &.active {
      &:after {
        transform: rotate(180deg);
      }
    }

    &:after {
      position: absolute;
      z-index: 1;
      right: 16px;
      top: 12px;
      margin: auto;
      content: "";
      margin-left: 8px;
      width: 24px;
      height: 24px;
      background: ${cssImg(ImageEnum.downArrowLight)};
    }

    select {
      width: 100%;
      font-family: BryantWebBold, sans-serif;
      background: var(--input-default-bg);
      border: var(--input-default-border);
      box-shadow: var(--input-shadow);
      color: var(--input-text-color);
      height: 48px;
      box-sizing: border-box;
      padding: 0 16px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding-right: 40px;
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;

      &:focus {
        border: var(--input-focus-border);
        background: var(--input-focus-bg);
        outline: var(--input-focus-border);
      }
    }
  `;

  static t = {
    menu: styled.div``,
  };

  state = XInit(
    class {
      active = false;
    }
  );

  render(Container?) {
    return (
      <Container
        theme={state.theme}
        className={cx({ above: this.props.above })}
      >
        <select
          id={this.props.id}
          onChange={(e) => {
            const value = e.target.value;
            this.props.onChange?.(value);
          }}
          required={this.props.required}
          aria-invalid={this.props.ariaInvalid}
          aria-describedby={this.props.ariaDescribedby}
        >
          {this.props.options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </Container>
    );
  }
}
