import React, { useContext, useEffect, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { useParams } from "react-router-dom";

import StoryItem from "../components/Story";
import MoreStories from "../components/MoreStories";
import StoriesEmptyState from "../components/StoryEmptyState";

const StoryContainer = styled.div`
  margin: 64px auto;
  max-width: 800px;

  a {
    text-decoration: none;
  }
`;

const Story = (props) => {
  const [story, setStory] = useState(null);
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(false);
  const theme: any = useContext(ThemeContext);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const url =
        window.location.hostname === "localhost"
          ? "https://signup-extension-data.s3.amazonaws.com/stories-dev.json"
          : "https://signup-extension-data.s3.amazonaws.com/stories.json";
      const data = await (await fetch(url)).json();

      const visible = data.filter((story) => !story.hidden);
      const story = data.find((story) => story._id === id);

      setStories(visible);
      setStory(story);
      setLoading(false);
    })();
  }, [setStory, setLoading, id]);

  if (loading) {
    return null;
  }

  return (
    <StoryContainer theme={theme}>
      {story ? (
        <>
          <StoryItem preview={false} story={story} />
          <MoreStories stories={stories} current={story} />
        </>
      ) : (
        <StoriesEmptyState search={false} />
      )}
    </StoryContainer>
  );
};

export default Story;
